import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import Card from '../Components/shared/Card/Card';
import PageIntro from '../Components/shared/PageIntro/PageIntro';
import LeitfadenImage from '../assets/img/florian-wehde-1uWanmgkd5g-unsplash.jpg';
import KontaktImage from '../assets/img/globelet-reusable-dsp8uHRlS6c-unsplash.jpg';

const Home = () => {
    const [t] = useTranslation();
    return (
        <>
            <PageIntro
                title={t('home.title')}
                lead={t('home.lead')}
            />
            <Container className="pb-5">
                <Row className="flex-wrap">
                    <Col lg={6} className="mb-3 md-lg-0 flex-column">
                        <Card
                            img={{
                                src: LeitfadenImage,
                                alt: 'Photo by Florian Wehde on Unsplash',
                                title: 'Photo by Florian Wehde on Unsplash',
                                heigt: '400',
                                loading: 'lazy',
                            }}
                            title='Leitfaden'
                            text='Hier finden Sie den Leitfaden „Abfallarme Großveranstaltungen“ und Muster-Vorlagen.'
                            link={{
                                linkText: t('buttons.toGuidelines'),
                                href: '/leitfaden'
                            }}
                        />
                    </Col>
                    <Col lg={6} className="mb-3 md-lg-0">
                        <Card
                            img={{
                                src: KontaktImage,
                                alt: 'Bild: Globelet Reusable on Unsplash',
                                title: 'Bild: Globelet Reusable on Unsplash',
                                heigt: '400',
                                loading: 'lazy',
                            }}
                            title='Kontakte'
                            text='Hier finden Sie Unternehmen und Behörden, die Sie bei der Organisation und Durchführung mit nachhaltigen Angeboten und Informationen unterstützen.'
                            link={{
                                linkText: t('buttons.toContacts'),
                                href: '/kontakte'
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Home;