const resources = {
    de: {
        base: {
            navigation: {
                home: 'Home',
                guideline: 'Leitfaden',
                contacts: {
                    title: 'Kontakte',
                    all: 'Alle',
                },
            },
            a11y: {
                altTags: {
                    senUvkLogo:
                        'Senatsverwaltung für Mobilität, Verkehr, Klimaschutz und Umwelt',
                    bsrLogo: 'BSR Berliner Stadtreinigungsbetriebe',
                },
                ariaLabels: {
                    showMenu: 'Menü anzeigen',
                    hideMenu: 'Menü verbergen',
                    loading: 'Lädt gerade',
                },
            },
            buttons: {
                toContacts: 'Gehe zu den Kontakten',
                toGuidelines: 'Leitfaden ansehen',
                toContactForm: 'Kontaktieren Sie uns',
            },
            home: {
                title: 'Abfallarme Großveranstaltungen',
                lead: '<p>Jährlich entstehen in Berlin bei öffentlichen Veranstaltungen rund 1.600 Tonnen Abfall, davon allein 750 Tonnen bei Großveranstaltungen mit mehr als 100.000 Besucher*innen und mit einem hohen Abfallver­meidungs- und -verwertungspotenzial. Das wollen wir ändern!</p><p>Im Rahmen der Zero-Waste-Strategie des Landes Berlin ist unser Ziel, das Restabfallaufkommen der Stadt sowohl in privaten Haushalten als auch im gewerblichen Bereich drastisch zu reduzieren und nicht vermeidbare Abfälle optimal als Ressource zu nutzen.</p><p>Der hier vorliegende Leitfaden „Abfallarme Großveranstaltungen“ enthält konkrete Maßnahmen, mit denen sofort das bei der Durchführung von Veran­staltungen anfallende Abfallaufkommen deutlich verringert werden kann. Für die Organisation der Veranstaltungen finden Sie auf dieser Webseite zudem Kontakte zu Unternehmen und Behörden, die Sie mit nachhaltigen Angeboten und Informationen unterstützen können.</p><p>Mit der Umsetzung und Durch­führung abfallarmer Großveranstaltungen werden die Ziele des Landes Berlin im Sinne des Zero-Waste-Leitbildes umgesetzt und ein wichtiger Beitrag zum Klima- und Ressourcenschutz geleistet.</p><p><b>Helfen Sie mit, unsere Stadt abfallarm und damit nachhaltiger zu gestalten!</b></p>',
            },
            contacts: {
                title: 'Kontakte',
                lead: 'Unternehmen und Behörden, die Sie in der Organisation und Durchführung von abfallarmen Veranstaltungen unterstützen, finden Sie hier nach Branchen sortiert.',
                subText: 'Haben Sie Interesse hier gelistet zu werden?',
                filterTitle: 'Nach Branche filtern',
                select: {
                    allOption: 'Alle Branchen',
                    label: 'Branche',
                },
            },
            guidelines: {
                title: 'Leitfaden und Muster-Vorlagen',
                lead: 'Den Leitfaden „Abfallarme Großveranstaltungen“ und die zugehörigen Muster-Vorlagen können Sie hier herunterladen',
            },
            footer: {
                infoText:
                    'Dieses Projekt ist eine gemeinsame Aktion der Berliner Senatsverwaltung für Mobilität, Verkehr, Klimaschutz und Umwelt und der Berliner Stadtreinigungsbetriebe AöR',
            },
            errors: {
                general: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
            }
        },
    },
};

export default resources;
