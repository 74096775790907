import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageIntro from '../Components/shared/PageIntro/PageIntro';

const PDFDownloader = ({ title, href, size }) => (
    <div>
        <a href={href} target="_blank" rel="noreferrer" className="btn btn-primary">
            {title}
        </a>
        <p className="small text-muted">PDF {size}</p>
    </div>
);

PDFDownloader.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string,
    size: PropTypes.string,
};

const Guideline = () => {
    const [t] = useTranslation();

    return (
        <>
            <PageIntro
                title={t('guidelines.title')}
                lead={t('guidelines.lead')}
            >
                <div className="my-5">
                    {/* <PDFDownloader href={`${baseURLPrefix}/guideline`} title={'Leitfaden Grossveranstaltungen'} size={'1,7 MB'}/> */}
                    <PDFDownloader href={'pdf/Leitfaden_Grossveranstaltungen.pdf'} title={'Leitfaden Grossveranstaltungen'} size={'1,7 MB'}/>
                </div>
                <div className="my-5">
                    <PDFDownloader href={'pdf/Leitfaden_Grossveranstaltungen_Anlage-1.pdf'} title={'Anlage 1: Mustervorlage Abfallkonzept'} size={'1,3 MB'}/>
                    <PDFDownloader href={'pdf/Leitfaden_Grossveranstaltungen_Anlage-2.pdf'} title={'Anlage 2: Erläuterungen Abfallkonzept'} size={'343 KB'}/>
                    <PDFDownloader href={'pdf/Leitfaden_Grossveranstaltungen_Anlage-3.pdf'} title={'Anlage 3: Planung Abfallmenge'} size={'499 KB'}/>
                    <PDFDownloader href={'pdf/Leitfaden_Grossveranstaltungen_Anlage-4.pdf'} title={'Anlage 4: Mustervorlage Abfallbericht'} size={'571 KB'}/>
                </div>
                <div className="my-5">
                    <PDFDownloader href={'pdf/Bildnachweise_Website.pdf'} title={'Bildnachweise'} size={'2,6 MB'}/>
                </div>
            </PageIntro>
        </>
    );
};

export default Guideline;