import PropTypes from 'prop-types';
import {
    Container,
    Button,
} from 'reactstrap';
import parser from 'html-react-parser';

const PageIntro = ({
    title,
    lead,
    subText,
    callToAction,
    children,
    className,
}) => (
    <Container className={className || 'py-5'}>
        <h1>{title}</h1>
        {lead && (<div className="lead mb-2">{parser(lead)}</div>)}
        {subText && (<div className="mb-2">{parser(subText)}</div>)}
        {
            callToAction
            && (
                callToAction.link
                    ? (
                        <div className="mb-2">
                            <a className="btn btn-primary" title={callToAction.link} href={callToAction.link}>
                                {callToAction.text}
                            </a>
                        </div>
                    )
                    : (
                        <Button
                            color="primary"
                            onClick={callToAction.onClick}
                        >
                            {callToAction.text}
                        </Button>
                    )
            )
        }
        {children}
    </Container>
);

PageIntro.propTypes = {
    className: PropTypes.string,
    lead: PropTypes.string,
    subText: PropTypes.string,
    title: PropTypes.string.isRequired,
    callToAction: PropTypes.shape({
        link: PropTypes.string,
        onClick: PropTypes.func,
        text: PropTypes.string.isRequired,
    }),
    children: PropTypes.any,
};

export default PageIntro;