const DataPrivacy = () => (
    <div className="py-5">
        <div aria-label="Datenschutzerkläruung"></div>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3>Datenschutzerklärung</h3>
                        <p>Informationen zur Datenerhebung gemäß Artikel 13 DS-GVO</p>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-content">
                        <p>
                            <strong>Verantwortlichen Stelle und Datenschutzbeauftragte</strong>
                        </p>
                        <p>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                        </p>
                        <p>
                            Berliner Stadtreinigungsbetriebe, Anstalt des öffentlichen Rechts (BSR)
                        </p>
                        <p>
                            Ringbahnstr. 96<br/>
                            12103 Berlin<br/>
                            Tel. 030 7592-4900<br/>
                            E-Mail: <a href="mailto:service@bsr.de">service@bsr.de</a> <br />
                            Webside: <a href='https://www.bsr.de/'>www.bsr.de</a><br />
                        </p>
                        <p>
                            Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die
                            Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
                            Kontaktdaten o. Ä.).
                        </p>
                        <p>
                            Die betrieblichen Datenschutzbeauftragten der BSR sind unter der E-Mail-Adresse  <a href="mailto:Datenschutz@BSR.de">Datenschutz@BSR.de</a> erreichbar.
                        </p>
                        <br/>
                        <p>
                            <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
                        </p>
                        <p>
                            Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung
                            möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den
                            Widerruf genügt eine formlose Mitteilung per E-Mail an Datenschutz@BSR.de. Die
                            Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                            unberührt.
                        </p>
                        <p>
                            <strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong>
                        </p>
                        <p>
                            Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein
                            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
                            Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der
                            Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres
                            Unternehmens befindet.<br />
                            Die für die BSR zuständige Aufsichtsbehörde erreichen Sie folgendermaßen: 
                        </p>
                        <p>
                            Berliner Beauftragte für Datenschutz und Informationsfreiheit<br/>
                            Friedrichstr. 219<br/>
                            10969 Berlin<br/>
                            Telefon: 030 13889-0<br />
                            Telefax: 030 2155050<br />
                            E-Mail: <a href="mailto:mailbox@datenschutz-berlin.de">mailbox@datenschutz-berlin.de</a>
                        </p>
                        <br />
                        <p>
                            <strong>Recht auf Datenübertragbarkeit</strong>
                        </p>
                        <p>
                            Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
                            Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu
                            lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die
                            direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
                            nur, soweit es technisch machbar ist. 
                        </p>
                        <p>
                            <strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong>
                        </p>
                        <p>
                            Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht
                            auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
                            Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf.
                            ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und
                            auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
                            jederzeit über die oben angegebenen Kontaktmöglichkeiten an uns wenden. 
                        </p>
                        <p>
                            <strong>Allgemeines zur Datenverarbeitung</strong>
                        </p>
                        <p className="mx-2">
                            <i className='text-secondary'>a) Umfang der Verarbeitung personenbezogener Daten</i>
                        </p>
                        <p>
                            Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur,
                            soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und
                            Leistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten
                            unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme
                            gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus
                            tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch
                            gesetzliche Vorschriften gestattet ist.
                        </p>
                        <p>
                            Die Webseite <a href="https://www.abfallarmeveranstaltungen-berlin.de">www.abfallarmeveranstaltungen-berlin.de</a> erhebt und verarbeitet keine
                            personenbezogenen Daten. Es werden keine Dienste Dritter verwendet und kein Content
                            von anderen Quellen außerhalb der BSR nachgeladen.
                        </p>
                        <p className="mx-2">
                            <i className='text-secondary'>b) Rechtsgrundlage für die Verarbeitung personenbezogener Daten</i>
                        </p>
                        <p>
                            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der
                            betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung
                            (DSGVO) als Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
                        </p>
                        <p>
                            Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages,
                            dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b
                            DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
                            Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                        </p>
                        <p>
                            Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
                            Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c
                            DSGVO als Rechtsgrundlage.
                        </p>
                        <p>
                            Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
                            natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen,
                            dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
                        </p>
                        <p>
                            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens
                            oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und
                            Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1
                            lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
                        </p>
                        <p className="mx-2">
                            <i className='text-secondary'>c) Datenlöschung und Speicherdauer</i>
                        </p>
                        <p>
                            Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt,
                            sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus dann 
                            erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in
                            unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
                            Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten
                            erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene
                            Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung
                            der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                        </p>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default DataPrivacy;