import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';

import SenUvkLogo from '../../../assets/img/logo-sen-uvk.png';
import BsrLogo from '../../../assets/img/bsr-logo_4x.png';

const scrollToTop = () => window.scrollTo(0, 0);

const Footer = () => {
    const [t] = useTranslation();

    const [toplinkVisible, setToplinkVisible] = useState(false);

    const eventTimeoutRef = useRef();
    useEffect(
        () => {
            const toggleTopLink = () => {
                if (eventTimeoutRef.current) {
                    window.clearTimeout(eventTimeoutRef.current);
                }

                eventTimeoutRef.current = window.setTimeout(
                    () => {
                        if (window.scrollY >= 128) {
                            setToplinkVisible(true);
                        } else {
                            setToplinkVisible(false);
                        }
                    },
                    100,
                );
            };

            window.addEventListener('scroll', toggleTopLink);
            return () => window.removeEventListener('scroll', toggleTopLink);
        },
        [],
    );

    return (
        <footer
            className={[styles.footer, 'bg-black', 'py-5'].join(' ')}
            role="contentinfo"
        >
            <Container>
                <Row>
                    <Col lg={6}>
                        <Row className="mb-3">
                            <Col
                                s={6}
                                className="mb-3 d-flex align-items-center"
                            >
                                <picture className="me-4">
                                    <img
                                        src={BsrLogo}
                                        alt={t('a11y.altTags.bsrLogo')}
                                        width="50px"
                                    />
                                </picture>
                                <picture>
                                    <img
                                        src={SenUvkLogo}
                                        alt={t('a11y.altTags.senUvkLogo')}
                                        width="300px"
                                    />
                                </picture>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="text-white">
                                    {t('footer.infoText')}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={3}>
                        <Nav vertical>
                            <NavItem>
                                <Link to="impressum" className="px-0 px-lg-3 nav-link">
                                    Impressum
                                </Link>
                            </NavItem>
                            <NavItem>
                                <a href="https://www.bsr.de/kontakt-19938.php" className="px-0 px-lg-3 nav-link">
                                    Kontakt
                                </a>
                            </NavItem>
                            <NavItem>
                                <Link to="datenschutz" className="px-0 px-lg-3 nav-link">
                                    Datenschutzhinweise
                                </Link>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://www.bsr.de/barrierefreiheit-28021.php" className="px-0 px-lg-3">
                                    Barrierefreiheit
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col xs={12} lg={3}>
                        <Nav vertical>
                            <NavItem>
                                <a href="https://www.bsr.de/" className="px-0 px-lg-3 nav-link">
                                    Berliner Stadtreinigungsbetriebe
                                </a>
                            </NavItem>
                            <NavItem>
                                <a href="https://www.berlin.de/sen/uvk/" className="px-0 px-lg-3 nav-link">
                                    Senatsverwaltung für Mobilität, Verkehr, Klimaschutz und Umwelt
                                </a>
                            </NavItem>
                        </Nav>
                    </Col>
                </Row>
            </Container>
            <Button
                className={[
                    'btn-link',
                    styles.toplink,
                    toplinkVisible ? styles.toplinkVisible : '',
                ].join(' ')}
                onClick={scrollToTop}
                title="Zum Seitenfang scrollen"
            >
                <i className="bi bi-arrow-up fs-3"></i>
            </Button>
        </footer>
    );
};

export default Footer;
