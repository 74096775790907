import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Spinner.module.scss';

const Spinner = () => {
    const [t] = useTranslation();
    return (
        <div
            className="d-flex align-items-center justify-content-center my-5"
            aria-label={(t('a11y.ariaLabels.loading'))}>
            <span className={styles.spinner} />
        </div>
    );
};

export default Spinner;