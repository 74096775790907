import PropTypes from 'prop-types';
import React from 'react';
import {
    Badge,
    Card, CardBody, Col, Row,
} from 'reactstrap';

const SectorCard = ({
    city,
    email,
    fax,
    homepage,
    mobile,
    name,
    phone,
    street,
    zip,
    description,
    sectors,
    oid,
    sectorsAttributes,
}) => (
    <Card>
        <CardBody>
            <Row>
                <Col lg={4} className="mb-3 mb-lg-0">
                    <h3 className="h4">{name}</h3>
                    <p className="mb-0">
                        {street}
                        <br />
                        {`${zip} ${city}`}
                    </p>
                </Col>
                <Col lg={4} className={['mb-3', 'mb-lg-0'].join(' ')}>
                    {phone && (<p className="mb-0">
                        <i className="me-2 bi bi-telephone"></i>
                        <a href={`tel:${phone}`}>{phone}</a>
                    </p>)}
                    {mobile && (<p className="mb-0">
                        <i className="me-2 bi bi-phone"></i>
                        <a href={`tel:${mobile}`}>{mobile}</a>
                    </p>)}
                    {fax && (<p className="mb-0">
                        <i className="me-2 bi bi-printer"></i>
                        <a href={`tel:${fax}`}>{fax}</a>
                    </p>)}
                    {email && (<p className="mb-0">
                        <i className="me-2 bi bi-globe"></i>
                        <a href={`mailto:${email}`}>{email}</a>
                    </p>)}
                    {homepage && (<p className="mb-0">
                        <i className="me-2 bi bi-envelope"></i>
                        <a href={homepage.startsWith('http') ? homepage : `//${homepage}`} target="_blank" rel="noreferrer" title={homepage}>zur Webseite</a>
                    </p>)}
                </Col>
                <Col lg={4} className="mb-3 mb-lg-0">
                    <p className="m-0">{description}</p>
                </Col>
                <Col lg={12} className="mt-3 mb-lg-0">
                    {sectors.map(s => {
                        const foundSector = sectorsAttributes.find(sector => sector.id === s);

                        if (!foundSector) {
                            return <></>;
                        }

                        return (
                            <Badge
                                color="secondary"
                                pill
                                key={`${oid}_${s}`}
                                className="me-2"
                            >
                                {foundSector.title}
                            </Badge>
                        );
                    })}
                </Col>
            </Row>
        </CardBody>
    </Card >
);


SectorCard.propTypes = {
    category: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    fax: PropTypes.string,
    homepage: PropTypes.string,
    mobile: PropTypes.string,
    name: PropTypes.string,
    oid: PropTypes.string,
    phone: PropTypes.string,
    region: PropTypes.string,
    sectors: PropTypes.arrayOf(PropTypes.string),
    sectorsAttributes: PropTypes.arrayOf(PropTypes.shape({
        count: PropTypes.number,
        id: PropTypes.string,
        oid: PropTypes.string,
        title: PropTypes.string,
    })),
    street: PropTypes.string,
    zip: PropTypes.string,
};

export default SectorCard;