import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';


const Tracker = ({ children }) => {
    const referrerRef = useRef(document.referrer);
    const location = useLocation();

    const ROOT_REST_URL = process.env.REACT_APP_ROOT_REST_URL || 'cmsbs/rest';

    useEffect(() => {
        const logger = async () => {
            await fetch(`${ROOT_REST_URL}/de.bsr.av.api/logger`, {
                headers: {
                    referrer: referrerRef.current,
                    location: location.pathname + location.search,
                }
            });
        };

        logger();
        referrerRef.current = '';
    }, [referrerRef, location.pathname, location.search, ROOT_REST_URL]);

    return (
        <>
            {children}
        </>
    );
};

Tracker.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default Tracker;