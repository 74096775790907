import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './locales/locales';

i18n.use(initReactI18next).init({
    // debug: true,
    resources,
    fallbackLng: 'de',
    ns: ['base'],
    defaultNS: 'base',
    lng: 'de',

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
