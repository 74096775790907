import React, { useCallback, useEffect, useState } from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import styles from './Navigation.module.scss';

import SenUvkLogo from '../../../assets/img/logo-sen-uvk.png';
import BsrLogo from '../../../assets/img/bsr-logo_4x.png';
import baseURLPrefix from '../../../config/baseURLPrefix';

const Navigation = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [navOpen, setNavOpen] = useState(false);
    const [sectors, setSectors] = useState([]);

    useEffect(() => {
        const doLoadData = async () => {
            const res = await fetch(`${baseURLPrefix}/sectors`);
            const { sectors } = await res.json();
            setSectors(sectors);
        };

        doLoadData();
    }, [setSectors]);

    const toggleNav = useCallback(() => {
        setNavOpen(!navOpen);
    }, [setNavOpen, navOpen]);

    const onClick = useCallback(
        (link) => {
            navigate(link);
        },
        [navigate]
    );

    useEffect(
        () => {
            window.scrollTo(0,0);
        },
        [location.pathname],
    );

    return (
        <header
            className={[styles.navigation, 'sticky-top', 'bg-white'].join(' ')}
            role="banner"
        >
            <Container>
                <Navbar expand="md" container={false} light>
                    <NavbarBrand href="/">
                        <img
                            src={BsrLogo}
                            alt={t('a11y.altTags.bsrLogo')}
                            width="50px"
                            height="auto"
                        />
                        <img
                            src={SenUvkLogo}
                            alt={t('a11y.altTags.senUvkLogo')}
                            className="ms-3"
                            width="300px"
                        />
                    </NavbarBrand>
                    <NavbarToggler
                        onClick={toggleNav}
                        aria-label={
                            navOpen
                                ? t('a11y.ariaLabels.hideMenu')
                                : t('a11y.ariaLabels.showMenu')
                        }
                    />
                    <Collapse
                        navbar
                        isOpen={navOpen}
                        onClick={() => {
                            if (window.innerWidth < 768) {
                                toggleNav();
                            }
                        }}
                    >
                        <Nav navbar className="ms-auto">
                            <NavItem>
                                <Link
                                    to="/"
                                    className={`nav-link ${location.pathname === '/'
                                        ? 'active'
                                        : ''
                                    }`}
                                    aria-current={
                                        location.pathname === '/'
                                            ? 'page'
                                            : null
                                    }
                                >
                                    {t('navigation.home')}
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    to="/leitfaden"
                                    className={`nav-link ${location.pathname === '/leitfaden'
                                        ? 'active'
                                        : ''
                                    }`}
                                    aria-current={
                                        location.pathname === '/leitfaden'
                                            ? 'page'
                                            : null
                                    }
                                >
                                    {t('navigation.guideline')}
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown inNavbar nav>
                                <DropdownToggle
                                    caret
                                    nav
                                    className={`${location.pathname === '/kontakte'
                                        ? 'active'
                                        : ''
                                    }`}
                                    aria-current={
                                        location.pathname === '/kontakte'
                                            ? 'page'
                                            : null
                                    }
                                    onClick={e => e.stopPropagation()}
                                >
                                    {t('navigation.contacts.title')}
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem
                                        onClick={() => onClick('/kontakte')}
                                    >
                                        {t('navigation.contacts.all')}
                                    </DropdownItem>
                                    {sectors.map((s) => (
                                        <DropdownItem
                                            key={s.oid}
                                            onClick={() =>
                                                onClick(
                                                    `/kontakte?category=${s.id}`
                                                )
                                            }
                                        >
                                            {s.title}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Container>
        </header>
    );
};

export default Navigation;
