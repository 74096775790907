import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Input, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SectorCard from '../Components/shared/SectorCard/SectorCard';
import Spinner from '../Components/shared/Spinner/Spinner';
import { useSearchParams, Link } from 'react-router-dom';
import PageIntro from '../Components/shared/PageIntro/PageIntro';
import baseURLPrefix from '../config/baseURLPrefix';
import styles from './Contacts.module.scss';

const Contacts = () => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    const [formLengths, setFormLengths] = useState({});

    const [loading, setLoading] = useState(false);
    const [sectors, setSectors] = useState([]);
    const [formularSectors, setFormularSectors] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [filterBarSticky, setFilterBarSticky] = useState(false);

    const [contactFormLoading, setContactFormLoading] = useState(false);
    const [contactFormSuccess, setContactFormSuccess] = useState(false);
    const [contactFormError, setContactFormError] = useState(false);
    const [contactAccordionOpen, setContactAccordionOpen] = useState(null);

    const selectedSector = searchParams.get('category');

    useEffect(() => {
        const doLoadInfo = async () => {
            setLoading(true);
            try {
                const res = await fetch(
                    `${baseURLPrefix}/addresses${
                        selectedSector ? `?category=${selectedSector}` : ''
                    }`
                );
                const { sectors, addresses } = await res.json();
                setLoading(false);
                setSectors(sectors);
                setAddresses(addresses);
            } catch(error) {
                console.log(error);
                setLoading(false);
            }
        };

        doLoadInfo();
    }, [selectedSector]);

    useEffect(() => {
        const doLoadFormularSectors = async () => {
            try {
                const res = await fetch(`${baseURLPrefix}/sectors?formular=true`);
                const { sectors } = await res.json();
                setFormularSectors(sectors);
            } catch(error) {
                console.log(error);
                setFormularSectors([]);
            }
        };

        doLoadFormularSectors();
    }, []);

    useEffect(() => {
        const elWrapper = document.querySelector('#filterBarWrapper');
        const observerWrapper = new IntersectionObserver(
            ([e]) => setFilterBarSticky(e.intersectionRatio < 1),
            { threshold: [1] }
        );
        observerWrapper.observe(elWrapper);
    }, []);

    const handleContactAccordionOpen = useCallback(
        () => setContactAccordionOpen(lastState => !lastState),
        [],
    );

    const submitForm = useCallback(
        async event => {
            event.preventDefault();
            const values = Object.fromEntries(new FormData(event.target));
            
            setContactFormError(false);
            setContactFormLoading(true);
            try {
                const res = await fetch(
                    `${baseURLPrefix}/address`,
                    {
                        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                        //mode: 'cors', // no-cors, *cors, same-origin
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Safe': 'Yes',
                        },
                        body: JSON.stringify(values)
                    }
                );
                const { success, error } = await res.json();
                setContactFormLoading(false);

                if (success) {
                    setContactFormSuccess(true);
                }

                if (error) {
                    setContactFormError(error);
                }
            } catch(err) {
                console.log(err);
                setContactFormLoading(false);
                setContactFormError('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
            }
        },
        [],
    );

    return (
        <>
            <PageIntro
                className={'pt-5 pb-1'}
                title={t('contacts.title')}
                lead={t('contacts.lead')}
            />
            <Container className="pb-4">
                <div className="accordion" id="accordion">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded={contactAccordionOpen ? 'true' : 'false'}
                                aria-controls="collapseOne"
                                onClick={handleContactAccordionOpen}
                            >
                                Wenn Sie Interesse haben, hier als Kontakt aufgeführt zu werden, klicken Sie hier.
                            </button>
                        </h2>
                        <div
                            id="collapseOne"
                            className={`accordion-collapse collapse ${contactAccordionOpen ? 'show' : ''}`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordion"
                        >
                            <div className="accordion-body">
                                {
                                    contactFormSuccess && <p className="alert alert-success">Adresse erfolgreich zur Bearbeitung eingereicht. Bei Fragen melden wir uns bei Ihnen.</p>
                                }
                                {
                                    contactFormError && <p className="alert alert-danger">{contactFormError}</p>
                                }
                                {
                                    contactFormLoading ? (
                                        <Spinner />
                                    ) : (
                                        contactFormSuccess ? <></> : (
                                            <form onSubmit={submitForm}>
                                                <Row className="g-4">
                                                    <Col xs="12">
                                                        <h3 className="mb-0">Neuen Kontakt einreichen</h3>
                                                    </Col>
                                                    <Col xs="12">
                                                        <label htmlFor="av_company_name">Unternehmensname *</label>
                                                        <Input
                                                            name="av_company_name"
                                                            id="av_company_name"
                                                            required
                                                            maxLength="256"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    av_company_name: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.av_company_name || 0)} / 256</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="email">E-Mail-Adresse *</label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            required
                                                            maxLength="254"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    email: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.email || 0)} / 254</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="homepage">Homepage</label>
                                                        <Input
                                                            type="text"
                                                            name="homepage"
                                                            id="homepage"
                                                            maxLength="256"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    homepage: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.homepage || 0)} / 256</span>
                                                    </Col>
                                                    <Col xs="12">
                                                        <label htmlFor="street">Straße / Adresse *</label>
                                                        <Input
                                                            type="text"
                                                            name="street"
                                                            id="street"
                                                            required
                                                            maxLength="256"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    street: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.street || 0)} / 256</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="zip">Postleitzahl *</label>
                                                        <Input
                                                            type="text"
                                                            name="zip"
                                                            id="zip"
                                                            required
                                                            maxLength="10"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    zip: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{10 - (formLengths.zip || 0)} / 10</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="city">Ort *</label>
                                                        <Input
                                                            type="text"
                                                            name="city"
                                                            id="city"
                                                            required
                                                            maxLength="256"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    city: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.city || 0)} / 256</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="phone">Telefonnummer</label>
                                                        <Input
                                                            type="tel"
                                                            name="phone"
                                                            id="phone"
                                                            maxLength="32"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    phone: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.phone || 0)} / 32</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="mobile">Mobiltelefon</label>
                                                        <Input
                                                            type="tel"
                                                            name="mobile"
                                                            id="mobile"
                                                            maxLength="32"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    mobile: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.mobile || 0)} / 32</span>
                                                    </Col>
                                                    <Col xs="12" sm="6">
                                                        <label htmlFor="fax">Fax</label>
                                                        <Input
                                                            type="tel"
                                                            name="fax"
                                                            id="fax"
                                                            maxLength="32"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    fax: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.fax || 0)} / 32</span>
                                                    </Col>
                                                    <Col xs="12">
                                                        <label htmlFor="av_description">Beschreibung / Anmerkungen</label>
                                                        <Input
                                                            type="textarea"
                                                            name="av_description"
                                                            id="av_description"
                                                            maxLength="500"
                                                            onChange={
                                                                event => setFormLengths(state => ({
                                                                    ...state,
                                                                    av_description: event?.target?.value?.length || 0,
                                                                }))
                                                            }
                                                        />
                                                        <span>{(formLengths.av_description || 0)} / 500</span>
                                                    </Col>
                                                    <Col xs="12">
                                                        <Label>Branchen</Label>
                                                        {!formularSectors && <Spinner />}
                                                        {formularSectors?.length === 0 && <p className="text-muted">Keine Branchen auswählbar</p>}
                                                        {formularSectors?.length > 0 && (
                                                            <div className="d-flex flex-wrap" style={{ columnGap: '2rem' }}>
                                                                {formularSectors.map(sector => (
                                                                    <FormGroup check key={sector.id} className="mx-2">
                                                                        <Input type="checkbox" name={`av_category_${sector.id}`} id={`av_category_${sector.id}`}/>
                                                                        <Label check htmlFor={`av_category_${sector.id}`}>
                                                                            {sector.title}
                                                                        </Label>
                                                                    </FormGroup>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col xs="12">
                                                        <Input
                                                            style={{ width: 'fit-content' }}
                                                            className="btn btn-primary"
                                                            type="submit"
                                                            value="Abschicken"
                                                        />
                                                    </Col>
                                                </Row>
                                            </form>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Container className="pb-3">
                <p className="alert alert-secondary">
                    {'Für die Umsetzung von Maßnahmen zur Abfallvermeidung auf (Groß-) Veranstaltungen wird Unternehmen auf dieser Seite eine Plattform geboten, Ihre Angebote zu platzieren. Die Senatsverwaltung für Mobilität, Verkehr, Klimaschutz und Umwelt und die Berliner Stadtreinigung (BSR) treffen keine Aussagen über Qualität und Umsetzung und übernehmen keine Verantwortung für die Vollständigkeit der hier beworbenen Angebote.'}
                </p>
            </Container>
            <div
                id="filterBarWrapper"
                className={
                    filterBarSticky
                        ? styles.filterWrapperStuck
                        : styles.filterWrapper
                }
            >
                <Container
                    id="filterBar"
                    className={[
                        filterBarSticky ? styles.stuck : '',
                    ].join(' ')}
                >
                    <Row>
                        <Col xs={12}>
                            <h2 className="h4 pb-2" id="filter_label">
                                {t('contacts.filterTitle')}
                            </h2>
                        </Col>
                    </Row>
                    <Row
                        className="row-cols-auto g-2"
                        role="radiogroup"
                        aria-controls="filter_results"
                        aria-labelledby="filter_label"
                    >
                        <Col>
                            <Link
                                className={`btn btn-outline-primary rounded-pill ${
                                    selectedSector ? '' : 'active'
                                }`}
                                to={'/kontakte'}
                                role="radio"
                            >
                                {t('contacts.select.allOption')}
                            </Link>
                        </Col>
                        {sectors.map((s) => (
                            <Col key={s.id}>
                                <Link
                                    role="radio"
                                    className={`btn btn-outline-primary rounded-pill ${
                                        selectedSector === s.id ? 'active' : ''
                                    }`}
                                    to={`/kontakte?category=${s.id}`}
                                >
                                    {s.title}
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <Container className="mb-3">
                    <Row id="filter_results" className="gy-3 list-unstyled" role="region" aria-live="polite" tag="ul">
                        {addresses?.map((address) => (
                            <Col xs={12} key={address.oid} tag="li">
                                <SectorCard
                                    {...address}
                                    sectorsAttributes={sectors}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            )}
        </>
    );
};

export default Contacts;
