import React, { Suspense } from 'react';
import Navigation from './Components/layout/Navigation/Navigation';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Spinner from './Components/shared/Spinner/Spinner';

import Home from './pages/Home';
import Guideline from './pages/Guideline';
import Contacts from './pages/Contacts';
import Impressum from './pages/Impressum';
import DataPrivacy from './pages/DataPrivacy';
import Footer from './Components/layout/Footer/Footer';

import './i18n/i18n'; // init translation
import Tracker from './Components/Tracker/Tracker';

function App() {
    return (
        <div className="App min-vh-100 d-flex flex-column">
            <HashRouter>
                <Tracker>
                    <Navigation />
                    <main className="flex-grow-1">
                        <Suspense fallback={<Spinner />}>
                            <Routes>
                                <Route
                                    path="/kontakte"
                                    element={<Contacts />}
                                />
                                <Route
                                    path="/leitfaden"
                                    element={<Guideline />}
                                />
                                <Route
                                    path="/impressum"
                                    element={<Impressum />}
                                />
                                <Route
                                    path="/datenschutz"
                                    element={<DataPrivacy />}
                                />
                                <Route path="/" element={<Home />} />
                            </Routes>
                        </Suspense>
                    </main>
                    <Footer />
                </Tracker>
            </HashRouter>
        </div>
    );
}

export default App;
