/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';
import {
    Card as BootstrapCard,
    CardBody,
    CardImg,
    CardText,
    CardTitle,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const Card = props => {
    const {
        img,
        title,
        text,
        link: {
            href,
            linkText,
        }
    } = props;

    return (
        <BootstrapCard className="h-100">
            <div className="position-relative">
                <CardImg
                    {...img}
                />
                {img.title && <span className="text-white" style={{ position: 'absolute', bottom: '0', right: '8px' }}>{img.title}</span>}
            </div>
            <CardBody className="d-flex flex-column justify-content-between">
                <div className="mb-2">
                    <CardTitle tag="h1" className="h5">
                        {title}
                    </CardTitle>
                    <CardText>
                        {text}
                    </CardText>
                </div>
                <Link to={href} className="btn btn-primary" style={{ width: 'fit-content' }}>{linkText}</Link>
            </CardBody>
        </BootstrapCard>
    );
};

Card.propTypes = {
    link: PropTypes.shape({
        linkText: PropTypes.string,
        href: PropTypes.string,
    }).isRequired,
    img: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        height: PropTypes.string,
        loading: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
    text: PropTypes.string,
    title: PropTypes.string,
};

export default Card;