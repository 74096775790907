const Impressum = () => (
    <div className="py-5">
        <div id="content" aria-label="Hauptinhalt"></div>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div>
                            <div>
                                <h1>Impressum</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-content">
                        <div><strong>Berliner Stadtreinigungsbetriebe</strong></div>
                        <div><strong>Anstalt des öffentlichen Rechts</strong></div>
                        <p>Ringbahnstr. 96<br/>
                        12103 Berlin<br/>
                            <br/>
                        Tel. 030 7592-4900<br/>
                        Fax 030 7592-2262<br/>
                            <br/>
                        Gerichtsstand: AG Berlin Charlottenburg, HRA 33292<br/>
                        Umsatzsteuer-Identifikationsnummer: DE 136 630 343
                        </p>
                        <p><br/>
                            <strong>Vorstand</strong><br/>
                        Stephanie Otto (Vorsitzende)<br/>
                        Dr. Christoph Vielhaber<br/>
                        Martin Urban
                        </p>
                        <p>&nbsp;</p>
                        <h3>Rechtliche Hinweise</h3>
                        <p><br/>
                            <strong>Haftung</strong><br/>
                        Die BSR erstellt alle Informationen dieser Website mit größtmöglicher Sorgfalt. Jedoch wird eine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität nicht übernommen. Die Nutzung der Website selbst oder der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers.<br/>
                            <br/>
                        Die BSR behält es sich ausdrücklich vor, Inhalte ganz oder teilweise ohne gesonderte Ankündigung zu ändern oder zu löschen.<br/>
                            <br/>
                        Bei namentlich gekennzeichnete Beiträge oder in interaktiven Teilen der Website (Blogs, Foren u.ä.) eingestellten Inhalten wird die Meinung des Autors wiedergegeben, die nicht mit der Meinung der BSR übereinstimmen muss.<br/>
                            <br/>
                        Durch die reine Nutzung der Website wird kein vertragliches Verhältnis an sich zwischen der BSR und dem Nutzer begründet.
                        </p>
                        <p><br/>
                            <strong>Websites Dritter/ Verlinkungen</strong><br/>
                        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Bei Kenntniserlangung von Rechtsverstößen werden diese Links jedoch unverzüglich gelöscht.
                        </p>
                        <p><br/>
                            <strong>Urheberrecht</strong><br/>
                        Für die auf der Website veröffentlichten Inhalte gilt das deutsche Urheberrecht. Jede danach nicht zulässige Nutzung oder Verwertung bedarf der schriftlichen Zustimmung der BSR.<br/>
                            <br/>
                        Das Einrichten von Links auf die Website der BSR ist zulässig, soweit damit keine geschäftliche oder Rufschädigung der BSR herbeigeführt wird. Die BSR ist darüber vom Linksetzenden per Email an service@BSR.de zu informieren.<br/>
                            <br/>
                        Vom Nutzer für die Website zur Verfügung gestellte Beiträge, Fotos und andere Inhalte dürfen von der BSR auf der Website uneingeschränkt dargestellt werden. Sofern Inhalte der Website auch für andere Medien oder die Darstellung auf anderen Websites (z.B. Social Networks wie Facebook) genutzt werden sollen, wird der Nutzer durch einen entsprechenden Hinweis auf der jeweiligen BSR-Seite darauf hingewiesen. Der Nutzer ist dafür verantwortlich, dass durch die Veröffentlichung seiner Inhalte keine Urheberrechte Dritter berührt oder verletzt werden.
                        </p>
                        <p>Sofern der Nutzer zu einem späteren Zeitpunkt möchte, dass dessen zur Verfügung gestellten oder selbst eingestellten Inhalte gelöscht werden und dies der BSR in geeigneter Form mitteilt, löscht die BSR die entsprechenden Inhalte von der eigenen Website und ebenfalls von anderen Websites, sofern diese im BSR-Zugriff liegen.</p>
                        <p><br/>
                            <strong>Interaktive Inhalte und Nutzerbeiträge</strong><br/>
                        Auf Seiten, auf denen Nutzer selbst Inhalte (z.B. Texte, Fotos, Grafiken) einstellen können (Blogs, Foren, Bildergenerator u.ä) oder auf denen durch Nutzer eingereichte Inhalte durch die BSR veröffentlicht werden, trägt der Nutzer die Verantwortung für den Inhalt und die Einhaltung der „Netikette“ (gutes Benehmen in der virtuellen Kommunikation) sowie die Wahrung des Urheberrechts.
                        </p>
                        <p>Insbesondere verboten sind verleumderische, ehrverletzende, pornografische, sexuell herabwürdigende, rassistische, gewaltverherrlichende und sonstige nichtrechtsstaatliche Inhalte.</p>
                        <p>Ein Nutzer darf nur Inhalte, gleich ob Text oder Bild, einreichen/ einstellen, wenn dadurch Urheberrechte Dritter nicht berührt werden. Für die eingestellten Inhalte ist der einstellende Nutzer haftbar. Die BSR wird Inhalte, die dem Vorgenannten nicht entsprechen, nach Kenntnisnahme von der Website entfernen.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Impressum;